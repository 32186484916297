import React, { FC, useCallback } from 'react';

import { LinkContainer, LinkName, LinkThumbnail } from './styles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

interface Props {
  thumbnail?: string | undefined;
  name: string;
  url?: any;
  path?: string | undefined;
  themeName?: string;
  fontName?: any;
  youtubeView?: boolean;
  setIsYoutube?: any;
  nickName: string;
}

const LinkItem: FC<Props> = ({
  thumbnail,
  name,
  url,
  path,
  themeName,
  fontName,
  youtubeView,
  setIsYoutube,
  nickName,
}) => {
  const navigate = useNavigate();

  const handleLink = useCallback(
    (link: string, name: string) => {
      window.location.href = `${link}`;
      ReactGA.event({ category: nickName, action: `${nickName} ${name}`, label: 'Link Button' });
      // window.open(`${link}`, '_blank');
      if (window.android) {
        window.android.getTitle(`${name}`);
      }
    },
    [nickName],
  );

  const handlePath = useCallback(
    (path: string, name: string) => {
      navigate(path);
      ReactGA.event({ category: nickName, action: nickName + name, label: 'Link Button' });
      if (window.android) {
        window.android.getTitle(`${name}`);
      }
    },
    [navigate, nickName],
  );

  const handleYoutube = useCallback(
    (name: string) => {
      setIsYoutube(true);
      ReactGA.event({ category: nickName, action: nickName + name, label: 'Link Button' });
    },

    [setIsYoutube, nickName],
  );

  return (
    <LinkContainer
      className={`${thumbnail ? 'isThumbnail' : ''} ${themeName === 'sphh' ? 'sphh' : ''} ${
        themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
      }`}
      onClick={() => {
        path ? handlePath(path, name) : youtubeView ? handleYoutube(name) : handleLink(url, name);
      }}
    >
      {thumbnail && (
        <LinkThumbnail
          src={thumbnail}
          alt="thumbnail"
          className={`${themeName === 'sphh' ? 'sphh' : ''} ${
            themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
          }`}
        />
      )}
      <LinkName
        className={`${themeName === 'sphh' ? 'sphh' : ''} ${
          themeName === 'dongwon' || themeName === 'kiff' || themeName === 'mhsc' ? 'dongwon' : ''
        }`}
        fontName={fontName}
      >
        {name}
      </LinkName>
    </LinkContainer>
  );
};

export default LinkItem;
