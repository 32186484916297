// import woodShowRoom from 'assets/images/woodShowRoom.png';
// import BannerImage from 'assets/images/BannerImage.png';

// 서대문 형무소 이미지
import sphhBG from 'assets/images/sphhBG.png';
import sphhImageLogo from 'assets/icons/sphhLogo.svg';
import HistoryHallMap from 'assets/images/HistoryHallMap.png';
import HistoryHall from 'assets/images/HistoryHall.png';
import SphhFeedImage1 from 'assets/images/sphhFeedImage1.png';
import SphhFeedImage2 from 'assets/images/sphhFeedImage2.png';
import SphhFeedImage3 from 'assets/images/sphhFeedImage3.png';
import SphhFeedImage4 from 'assets/images/sphhFeedImage4.png';
import SphhFeedImage5 from 'assets/images/sphhFeedImage5.png';
import SphhFeedImage6 from 'assets/images/sphhFeedImage6.png';

// 동원 이미지
import dongwomBG from 'assets/images/dongwonBG.png';
import dongwonImageLogo from 'assets/images/dongwonLogo.png';
import dongwonMall from 'assets/images/dongwonMall.png';
import dongwonKBO from 'assets/images/dongwonKBO.png';
import dongwonTV from 'assets/images/dongwonTV.png';

// 토니모리 이미지
import tonymolyBG from 'assets/images/tonymolyBG.png';
import tonymolyImageLogo from 'assets/images/tonymolyLogo.png';
import tonymolyMall from 'assets/images/tonymolyMall.png';
import tonymolyItem from 'assets/images/tonymolyItem.png';
import tonymolyJamong from 'assets/images/tonymolyJamong.png';

// 파스쿠찌 이미지
import passcucciBG from 'assets/images/passcucciBG.png';
import pascucciImageLogo from 'assets/images/pascucciLogo.png';
import pascucciMall from 'assets/images/pascucciMall.png';
import pascucciItem from 'assets/images/pascucciItem.png';
import pascucciJamong from 'assets/images/pascucciJamong.png';

// 루이까또즈 이미지
import louisquatorzeBG from 'assets/images/louisquatorzeBG.png';
import louisquatorzeImageLogo from 'assets/images/louisquatorzeLogo.png';
import louisquatorzeHistory from 'assets/images/louisquatorzeHistory.png';
import louisquatorzeProduct from 'assets/images/louisquatorzeProduct.png';
import louisquatorzePromotion from 'assets/images/louisquatorzePromotion.png';

// 삼립 이미지
import samlipBG from 'assets/images/samlipBG.png';
import samlipImageLogo from 'assets/images/samlipLogo.png';
import samlipMall from 'assets/images/samlipMall.png';
import samlipSalad from 'assets/images/samlipSalad.png';
import sanlipScoupSalad from 'assets/images/sanlipScoupSalad.png';

// 베베쿡 이미지
import bebecookBG from 'assets/images/bebecookBG.png';
import bebecookImageLogo from 'assets/images/bebecookLogo.png';
import bebeReview from 'assets/images/bebeReview.png';
import bebeYean from 'assets/images/bebeYean.png';
import bebeContack from 'assets/images/bebeContack.png';

// 비비큐 이미지
import bbqImageLogo from 'assets/images/bbqImageLogo.png';
import bbqMall from 'assets/images/bbqMall.png';
import bbqReview from 'assets/images/bbqReview.png';
import bbqEfficacy from 'assets/images/bbqEfficacy.png';
import bbqBG from 'assets/images/bbqBG.png';

// 오프라이스 이미지
import opriceImageLogo from 'assets/images/opriceLogo.png';
import opriceInfo from 'assets/images/opriceInfo.png';
import opriceNobrand from 'assets/images/opriceNobrand.png';
import opriceMukbang from 'assets/images/opriceMukbang.png';
import opriceBG from 'assets/images/opriceBG.png';

// 모나리자 이미지
import monnaLogo from 'assets/images/monnaLogo.png';
import monnaToday from 'assets/images/monnaToday.png';
import monnaMusic from 'assets/images/monnaMusic.png';
import monnaReview from 'assets/images/monnaReview.png';
import monnaBG from 'assets/images/monnaBG.png';

// kif 이미지
import kifLogo from 'assets/images/kifLogo.png';
import kifBG from 'assets/images/kifBG.png';
import kifMelon from 'assets/images/kifMelon.png';
import kifNews from 'assets/images/kifNews.png';
import kifInstaImage from 'assets/images/instaImage.png';

// mhsc 이미지
import mhscLogo from 'assets/images/mhscLogo.png';
import mhscBG from 'assets/images/mhscBG.png';
import mhscTicket1 from 'assets/images/mhscTicket1.png';
import mhscTicket2 from 'assets/images/mhscTicket2.png';

// 고디바 이미지
import godivaLogo from 'assets/images/godivaLogo.png';
import godivaBG from 'assets/images/godivaBG.png';
import godivaStore from 'assets/images/godivaStore.png';
// import godivaZefeto from 'assets/images/godivaZefeto.png';
import godivaEstation from 'assets/images/godivaEstation.png';

// 워터 제네시스 이미지
import watergLogo from 'assets/images/watergLogo.png';
import watergBG from 'assets/images/watergBG.png';
import watergYoutube from 'assets/images/watergYoutube.png';
import watergEarth from 'assets/images/watergEarth.png';
import watergAS from 'assets/images/watergAS.png';
import watergEnvex from 'assets/images/watergEnvex.png';

// bts_guess 이미지
import btsLogo from 'assets/images/btsLogo.png';
import btsBG from 'assets/images/btsBG.png';
import btsHome from 'assets/images/btsHome.png';
import btsInsta from 'assets/images/btsInsta.png';
import btsFacebook from 'assets/images/btsFacebook.png';
import btsYoutube from 'assets/images/btsYoutube.png';

// isaac 이미지
import isaacLogo from 'assets/images/isaacLogo.png';
import isaacBG from 'assets/images/isaacBG.png';
import isaacOrder from 'assets/images/isaacOrder.png';
import isaacMucbang from 'assets/images/isaacMucbang.png';
import isaacEvent from 'assets/images/isaacEvent.png';

// 정관장 이미지
import kgcshopLogo from 'assets/images/kgcshopLogo.png';
import kgcshopBG from 'assets/images/kgcshopBG.png';
import kgcshopBest from 'assets/images/kgcshopBest.png';
import kgcshopPromotion from 'assets/images/kgcshopPromotion.png';
import kgcshopEvent from 'assets/images/kgcshopEvent.png';

// 인셀덤 이미지
import incelldermLinkLogo from 'assets/images/incelldermLinkLogo.png';
import incelldermBG from 'assets/images/incelldermBG.png';
import incelldermSignup from 'assets/images/incelldermSignup.png';
import incelldermCatalog from 'assets/images/incelldermCatalog.png';
import incelldermYoutube from 'assets/images/incelldermYoutube.png';

// 까르띠에 100000번대
import cartierWineBG from 'assets/images/cartierWineBG.png';
import cartierWineLogo from 'assets/images/cartierWineLogo.png';
import cartierWineBell from 'assets/images/cartierWineBell.png';
import cartierWineEye from 'assets/images/cartierWineEye.png';
import cartierWineBoxing from 'assets/images/cartierWineBoxing.png';

// 까르띠에 000000번대
import cartierGrayLogo from 'assets/images/cartierGrayLogo.png';
import cartierGrayBell from 'assets/images/cartierGrayBell.png';
import cartierGrayEye from 'assets/images/cartierGrayEye.png';
import cartierGrayBoxing from 'assets/images/cartierGrayBoxing.png';
import cartierGrayBG from 'assets/images/cartierGrayBG.png';

// 로젠택배
import logenBG from 'assets/images/logenBG.png';
import logenLogo from 'assets/images/logenLogo.png';
import logenCall from 'assets/images/logenCall.png';
import logenPeople from 'assets/images/logenPeople.png';
import logenOffice from 'assets/images/logenOffice.png';

// 샤넬
import chanelBG from 'assets/images/chanelBG.png';
import chanelLogo from 'assets/images/chanelLogo.png';
import chanelHistory from 'assets/images/chanelHistory.png';
import chanelProduct from 'assets/images/chanelProduct.png';
import chanelPromotion from 'assets/images/chanelPromotion.png';

// 롯데 상품권
import lotteShoppingBG from 'assets/images/lotteShoppingBG.png';
import lotteShoppingLogo from 'assets/images/lotteShoppingLogo.png';
import lotteShoppingBuy from 'assets/images/lotteShoppingBuy.png';
import lotteShoppingList from 'assets/images/lotteShoppingList.png';
import lotteShoppingInfo from 'assets/images/lotteShoppingInfo.png';

// 롯데 백화점
import lotteDepLogo from "assets/images/Lotte/lotteDepLogo.png";
import lotteDepFirst from "assets/images/Lotte/firstItem.png"
import lotteDepSecond from "assets/images/Lotte/secondItem.png"
import lotteDepThird from "assets/images/Lotte/thirdItem.png"
import lotteDepBG from "assets/images/Lotte/lotteDepBG.png"


// pfzer
import pfzerBG from 'assets/images/pfzerBG.png';
import pfzerLogo from 'assets/images/pfzerLogo.png';
import pfzerCompany from 'assets/images/pfzerCompany.png';
import pfzerNew from 'assets/images/pfzerNew.png';
import pfzerProduct from 'assets/images/pfzerProduct.png';

// mamonde
import mamondeBG from 'assets/images/mamondeBG.png';
import mamondeLogo from 'assets/images/mamondeLogo.png';
import mamondeBest from 'assets/images/mamondeBest.png';
import mamondeSkin from 'assets/images/mamondeSkin.png';
import mamondeMakeUp from 'assets/images/mamondeMakeUp.png';

// spicyRamen
import spicyRamenBG from 'assets/images/spicyRamenBG.png';
import spicyRamenLogo from 'assets/images/spicyRamenLogo.png';
import spicyRamenProduct from 'assets/images/spicyRamenProduct.png';
import spicyRamenBrand from 'assets/images/spicyRamenBrand.png';
import spicyRamenContact from 'assets/images/spicyRamenContact.png';

//samsung buds
import samsungBudsBG from 'assets/images/samsungBudsBG.png';
import samsungBudsLogo from 'assets/images/samsungBudsLogo.png';
import samsungBuds2 from 'assets/images/samsungBuds2.png';
import samsungBuds from 'assets/images/samsungBuds.png';
import samsungEdition from 'assets/images/samsungEdition.png';

// penmax
import penmaxBG from 'assets/images/penmaxBG.png';
import penmaxLogo from 'assets/images/penmaxLogo.png';
import penmaxProduct from 'assets/images/penmaxProduct.png';
import penmaxNotice from 'assets/images/penmaxNotice.png';
import penmaxCantact from 'assets/images/penmaxCantact.png';

// marvel
import marvelBG from 'assets/images/marvelBG.png';
import marvelLogo from 'assets/images/marvelLogo.png';
import marvelMovies from 'assets/images/marvelMovies.png';
import marvelComics from 'assets/images/marvelComics.png';
import marvelNews from 'assets/images/marvelNews.png';

// cjlogistics
import cjlogisticsBG from 'assets/images/cjlogistics/cjlogisticsBG.png';
import cjlogisticsLogo from 'assets/images/cjlogisticsLogo.png';
import cjlogisticsFirstItem from 'assets/images/cjlogistics/firstItem.png'
import cjlogisticsSecondItem from 'assets/images/cjlogistics/secondItem.png'
import cjlogisticsThirdItem from 'assets/images/cjlogistics/thirdItem.png'

// mobis
import mobisBG from 'assets/images/mobisBG.png';
import mobisLogo from 'assets/images/mobisLogo.png';
import mobisInfo from 'assets/images/mobisInfo.png';
import mobisCareer from 'assets/images/mobisCareer.png';
import mobisNews from 'assets/images/mobisNews.png';

// vangoghmuseum
import vangoghmuseumBG from 'assets/images/vangoghmuseumBG.png';
import vangoghmuseumLogo from 'assets/images/vangoghmuseumLogo.png';
import vangoghmuseumTickets from 'assets/images/vangoghmuseumTickets.png';
import vangoghmuseumArt from 'assets/images/vangoghmuseumArt.png';
import vangoghmuseumWebstore from 'assets/images/vangoghmuseumWebstore.png';

// muzik
import muzikBG from 'assets/images/muzikBG.png';
import muzikLogo from 'assets/images/muzikLogo.png';
import muzikEvent from 'assets/images/muzikEvent.png';
import muzikMusic from 'assets/images/muzikMusic.png';
import muzikMagazin from 'assets/images/muzikMagazin.png';

// louvre
import louvreBG from 'assets/images/louvreBG.png';
import louvreLogo from 'assets/images/louvreLogo.png';
import louvreVisiter from 'assets/images/louvreVisiter.png';
import louvreDecouvrir from 'assets/images/louvreDecouvrir.png';
import louvreEnceMoment from 'assets/images/louvreEnceMoment.png';

// gwanak
import gwanakBG from 'assets/images/gwanakBG.png';
import gwanakLogo from 'assets/images/gwanakLogo.png';
import gwanakInfo from 'assets/images/gwanakInfo.png';
import gwanakSave from 'assets/images/gwanakSave.png';
import gwanakContact from 'assets/images/gwanakContact.png';

// looxLooe
import looxLooeBG from 'assets/images/looxLooeBG.png';
import looxLooeLogo from 'assets/images/looxLooeLogo.png';
import looxLooeProduct from 'assets/images/looxLooeProduct.png';
import looxLooeLook from 'assets/images/looxLooeLook.png';
import looxLooeItem from 'assets/images/looxLooeItem.png';

// baba
import babaBG from 'assets/images/babaBG.png';
import babaLogo from 'assets/images/babaLogo.png';
import babaProduct from 'assets/images/babaProduct.png';
import babaNew from 'assets/images/babaNew.png';
import babaIzzat from 'assets/images/babaIzzat.png';

// theoriginal
import theoriginalBG from 'assets/images/theoriginalBG.png';
import theoriginalLogo from 'assets/images/theoriginalLogo.png';
import theoriginalHome from 'assets/images/theoriginalHome.png';
import theoriginalNew from 'assets/images/theoriginalNew.png';

// whoau
import whoauBG from 'assets/images/whoauBG.png';
import whoauLogo from 'assets/images/whoauLogo.png';
import whoauNew from 'assets/images/whoauNew.png';
import whoauBest from 'assets/images/whoauBest.png';
import whoauEvent from 'assets/images/whoauEvent.png';

// celltrion
import celltrionBG from "assets/images/celltrion/celltrionBG.png"
import celltrionLogo from "assets/images/celltrion/celltrionLogo.png"
import celltrionFirstItem from 'assets/images/celltrion/firstItem.png'
import celltrionSecondItem from 'assets/images/celltrion/secondItem.png'
import celltrionThirdItem from 'assets/images/celltrion/thirdItem.png'

// amorepacific
import amorepacificBG from 'assets/images/amorepacific/amorepacificBG.png'
import amorepacificLogo from 'assets/images/amorepacific/amorepacificLogo.png'
import amorepacificFirstItem from 'assets/images/amorepacific/firstItem.png'
import amorepacificSecondItem from 'assets/images/amorepacific/seconItem.png'
import amorepacificThirdItem from 'assets/images/amorepacific/thirdItem.png'

// samyang
import samyangBG from 'assets/images/samyang/samyangBG.png'
import samyangLogo from 'assets/images/samyang/samyangLogo.png'
import samyangFirstItem from 'assets/images/samyang/firstItem.png'
import samyangSecondItem from 'assets/images/samyang/secondItem.png'
import samyangThirdItem from 'assets/images/samyang/thirdItem.png'

// samsung
import samsungBG from 'assets/images/samsung/samsungBG.png'
import samsungLogo from 'assets/images/samsung/samsungLogo.png'
import samsungFirstItem from 'assets/images/samsung/firstItem.png'
import samsungSecondItem from 'assets/images/samsung/secondItem.png'
import samsungThirdItem from 'assets/images/samsung/thirdItem.png'

// tamnaalesLinkList
import tamnaalesBG from 'assets/images/tamnaalesBG.png';
import tamnaalesLogo from 'assets/images/tamnaalesLogo.png';
import tamnaalesBlog from 'assets/images/tamnaalesBlog.png';
import tamnaalesTistory from 'assets/images/tamnaalesTistory.png';

//alpha
import alphaBG from 'assets/images/alpha/alpha_bg.png'
import alphaLogo from 'assets/images/alpha/alpha_main.png'
import alphaFirstItem from 'assets/images/alpha/alpha_item1.png'
import alphaSecondItem from 'assets/images/alpha/alpha_item2.png'
import alphaThirdItem from 'assets/images/alpha/alpha_item3.png'

//cgv
import cgvBG from 'assets/images/cgv/cvgbg.png'
import cgvLogo from 'assets/images/cgv/cgvLogo.png'
import cgvFirstItem from 'assets/images/cgv/firstItem.png'
import cgvSecondItem from 'assets/images/cgv/secondItem.png'
import cgvThirdItem from 'assets/images/cgv/thirdItem.png'

//hyundai
import hyundaiBG from 'assets/images/hyundai/hyundaiBG.png'
import hyundaiLogo from 'assets/images/hyundai/hyundaiLogo.png'
import hyundaiFirstItem from 'assets/images/hyundai/firstItem.png'
import hyundaiSecondItem from 'assets/images/hyundai/secondItem.png'
import hyundaiThirdItem from 'assets/images/hyundai/thirdItem.png'

//issac
import isaactoastBG from 'assets/images/isaactoast/issacBG.png'
import isaactoastLogo from 'assets/images/isaactoast/issacLogo.png'
import isaactoastFirstItem from 'assets/images/isaactoast/isfirstItem.png'
import isaactoastSecondItem from 'assets/images/isaactoast/issecondItem.png'
import isaactoastThirdItem from 'assets/images/isaactoast/isthirdItem.png'

//interparkticket
import interparkticketBG from 'assets/images/interparkticket/interparkBG.png'
import interparkticketLogo from 'assets/images/interparkticket/interparckLogo.png'
import interparkticketFirstItem from 'assets/images/interparkticket/inFirstItem.png'
import interparkticketSecondItem from 'assets/images/interparkticket/inSecondItem.png'
import interparkticketThirdItem from 'assets/images/interparkticket/inThirdItem.png'

//goverment24
import goverment24BG from 'assets/images/goverment24/gov24BG.png'
import goverment24Logo from 'assets/images/goverment24/gov24Logo.png'
import goverment24FirstItem from 'assets/images/goverment24/goFirstItem.png'
import goverment24SecondItem from 'assets/images/goverment24/goSecondItem.png'
import goverment24ThirdItem from 'assets/images/goverment24/goThirdItem.png'

//shinmyung
import shinmyungBG from 'assets/images/shinmyung/shinmyungBG.png'
import shinmyungLogo from 'assets/images/shinmyung/shinmyungLogo.png'
import shinmyungFirstItem from 'assets/images/shinmyung/firstItem.png'
import shinmyungSecondItem from 'assets/images/shinmyung/secondItem.png'
import shinmyungThirdItem from 'assets/images/shinmyung/thirdItem.png'

//gleam
import gleamBG from 'assets/images/gleammedia/gleamBG.png'
import gleamLogo from 'assets/images/gleammedia/gleamLogo.png'
import gleamFirstItem from 'assets/images/gleammedia/FirstItem.png'
import gleamSecondItem from 'assets/images/gleammedia/SeconItem.png'
import gleamThirdItem from 'assets/images/gleammedia/ThirdItem.png'

//happyhabit
import happyhabbitBG from 'assets/images/happyhabit/happyhabbitBG.png'
import happyhabbitLogo from 'assets/images/happyhabit/happyhabitIcon.png'
import happyhabbitFirstItem from 'assets/images/happyhabit/happyFirstImage.png'
import happyhabbitSecondItem from 'assets/images/happyhabit/happySecondImage.png'
import happyhabbitThirdItem from 'assets/images/happyhabit/happyThirdImage.png'

//루이비통
import louisBG from 'assets/images/louisvuitton/louisBG.png';
import louisLogo from 'assets/images/louisvuitton/louisLogo.png';
import louisFirstItem from 'assets/images/louisvuitton/FirstItem.png';
import louisSecondItem from 'assets/images/louisvuitton/SecondItem.png';
import louisThirdItem from 'assets/images/louisvuitton/ThirdItem.png';

import gucciBG from 'assets/images/gucci/gucciBG.png';
import gucciLogo from 'assets/images/gucci/gucciLogo.png';
import gucciFirstItem from 'assets/images/gucci/FirstItem.png';
import gucciSecondItem from 'assets/images/gucci/SecondItem.png';
import gucciThirdItem from 'assets/images/gucci/ThirdItem.png';

import ktngBG from 'assets/images/ktng/ktngBG.png';
import ktngLogo from 'assets/images/ktng/ktngLogo.png';
import ktngFirstItem from 'assets/images/ktng/FirstItem.png';
import ktngSecondItem from 'assets/images/ktng/SecondItem.png';
import ktngThirdItem from 'assets/images/ktng/ThirdItem.png';

import kobacoBG from 'assets/images/kobaco/KobacoBG.png';
import kobacoLogo from 'assets/images/kobaco/KobacoIcon.png';
import kobacoFirstItem from 'assets/images/kobaco/FirstItem.png';
import kobacoSecondItem from 'assets/images/kobaco/SecondItem.png';
import kobacoThirdItem from 'assets/images/kobaco/ThirdItem.png';

import futureBG from 'assets/images/futurebook/FutureBookBG.png';
import futureLogo from 'assets/images/futurebook/FutureBookIcon.png';
import futureFirstItem from 'assets/images/futurebook/FutureBookItem.png';

import URCheckBG from 'assets/images/urcheck/urcheckBG.png';
import URCheckLogo from 'assets/images/urcheck/urcheckLogo.png';
import URCheckItem from 'assets/images/urcheck/urcheckItem.png';

export const URCheckLinkList = {
    profile: {
        dongwonLogo: URCheckLogo,
        nickname: 'URCHECK',
    },
    theme: {
        backgroundImage: URCheckBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '학교정보 / 출입증 정보',
            url: 'https://www.pusan.ac.kr/kor/Main.do',
            thumbnail: URCheckItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '개인블록체인 이력정보',
            url: 'https://etherscan.io/address/0x1f9090aae28b8a3dceadf281b0f12828e676c326',
            thumbnail: URCheckItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '개인 맞춤 정보제공 서비스',
            url: 'https://library.yonsei.ac.kr/search/detail/CATTOT000000498157',
            thumbnail: URCheckItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/kgc_official_/',
            urlId: 'kgc_official_',
        },
        {
            category: 'Facebook',
            url: 'https://ko-kr.facebook.com/KGCsamsamstory',
            urlId: 'KGCsamsamstory',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/samsamstory/featured',
            urlId: '@featured',
        },
        {
            category: 'Homepage',
            url: 'https://www.futurenuri.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const futureBookLinkList = {
    profile: {
        dongwonLogo: futureLogo,
        nickname: 'URFUTUREBOOK',
    },
    theme: {
        backgroundImage: futureBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '도서 정보',
            url: 'https://www.yes24.com/Product/UsedShopHub/Hub/1397654',
            thumbnail: futureFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '도서블록체인 이력정보',
            url: 'https://etherscan.io/address/0x1f9090aae28b8a3dceadf281b0f12828e676c326',
            thumbnail: futureFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '도서맞춤 정보제공 서비스',
            url: 'https://library.yonsei.ac.kr/search/detail/CATTOT000000498157',
            thumbnail: futureFirstItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/kgc_official_/',
            urlId: 'kgc_official_',
        },
        {
            category: 'Facebook',
            url: 'https://ko-kr.facebook.com/KGCsamsamstory',
            urlId: 'KGCsamsamstory',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/samsamstory/featured',
            urlId: '@featured',
        },
        {
            category: 'Homepage',
            url: 'https://www.futurenuri.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};


export const kobacoLinkList = {
    profile: {
        dongwonLogo: kobacoLogo,
        nickname: 'kobaco Bank',
    },
    theme: {
        backgroundImage: kobacoBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '한국방속진흥공사 은행사이트',
            url: 'https://www.kbstar.com',
            thumbnail: kobacoFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '김연아 위키피디아 추천상품',
            url: 'https://namu.wiki/w/%EA%B9%80%EC%97%B0%EC%95%84',
            thumbnail: kobacoSecondItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '나이키 프로모션',
            url: 'https://www.nike.com',
            thumbnail: kobacoThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/kobacomain/',
            urlId: 'kobacomain',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/kobacomain',
            urlId: 'kobacomain',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@Kobacoac',
            urlId: '@Kobacoac',
        },
        {
            category: 'Homepage',
            url: 'https://www.kobaco.co.kr',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const ktngLinkList = {
    profile: {
        dongwonLogo: ktngLogo,
        nickname: 'KTNG',
    },
    theme: {
        backgroundImage: ktngBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'KT&G 기업소개',
            url: 'https://www.ktng.com/overview',
            thumbnail: ktngFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'KT&G 소식',
            url: 'https://www.ktng.com/ktngSns',
            thumbnail: ktngSecondItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'KT&G 지속가능경영',
            url: 'https://www.ktng.com/esgMain',
            thumbnail: ktngThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/ktng_daily/',
            urlId: 'ktng_daily',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/KTnGCorp/',
            urlId: 'KTnGCorp',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@ktng_official',
            urlId: '@ktng_official',
        },
        {
            category: 'Homepage',
            url: 'https://www.ktng.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};



export const gucciLinkList = {
    profile: {
        dongwonLogo: gucciLogo,
        nickname: 'gucci',
    },
    theme: {
        backgroundImage: gucciBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'History Of GUCCI',
            url: 'https://www.gucci.com/us/en/st/history-of-gucci',
            thumbnail: gucciFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'Metaverse',
            url: 'https://www.gucci.com/us/en/st/capsule/vault-metaverse',
            thumbnail: gucciSecondItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Valentine’s Day Gifts',
            url: 'https://www.gucci.com/us/en/ca/gifts/valentines-day-for-women-c-valentines-day-women',
            thumbnail: gucciThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/gucci/',
            urlId: 'gucci',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/gucci.korea/?locale=ko_KR',
            urlId: 'gucci.korea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCo6fjlKg6GuCmEMeqYbGJng',
            urlId: 'channel/UCo6fjlKg6GuCmEMeqYbGJng',
        },
        {
            category: 'Homepage',
            url: 'https://www.gucci.com/us/en/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};



export const louisVuittonLinkList = {
    profile: {
        dongwonLogo: louisLogo,
        nickname: 'LOUIS VUITTON',
    },
    theme: {
        backgroundImage: louisBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'World of Louis Vuitton',
            url: 'https://eu.louisvuitton.com/eng-e1/magazines',
            thumbnail: louisFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'Gift Guide',
            url: 'https://eu.louisvuitton.com/eng-e1/gifts/gifts-for-her/_/N-t19wefl6',
            thumbnail: louisSecondItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Treasure Trunk ' +
                'VIA Nicolas Ghesquière',
            url: 'https://eu.louisvuitton.com/eng-e1/stories/louis-vuitton-via-nicolas-ghesquiere',
            thumbnail: louisThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/louisvuitton/',
            urlId: 'louisvuitton',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/LouisVuitton/?locale=ko_KR',
            urlId: 'LouisVuitton',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@louisvuitton',
            urlId: '@louisvuitton',
        },
        {
            category: 'Homepage',
            url: 'https://eu.louisvuitton.com/eng-e1/homepage',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};


export const happyHabitLinkList = {
    profile: {
        dongwonLogo: happyhabbitLogo,
        nickname: 'happyhabit',
    },
    theme: {
        backgroundImage: happyhabbitBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '해피해빗 소식',
            url: 'https://happyhabit.oopy.io/news',
            thumbnail: happyhabbitFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '해피해빗 에코얼라이언스',
            url: 'https://www.happyhabit.co.kr/alliance',
            thumbnail: happyhabbitSecondItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '앱 다운로드 하기',
            url: 'https://play.google.com/store/apps/details?id=com.happyecophone.ecocup&hl=ko&gl=US',
            thumbnail: happyhabbitThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Kakaotalk',
            url: 'https://pf.kakao.com/_fZUxjK',
            urlId: '_fZUxjK',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/watch?v=SHRqfCx1W8E',
            urlId: 'SHRqfCx1W8E',
        },
        {
            category: 'Homepage',
            url: 'https://happyhabit.oopy.io/alliance',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};


export const sphhLinkList = {
    profile: {
        sphhLogo: sphhImageLogo,
        nickname: '',
    },
    theme: {
        themeName: 'sphh',
        backgroundImage: sphhBG,
        font: 'SeoulHangang',
    },
    list: [
        {
            id: 2,
            name: '역사관 VR보기',
            url: 'http://www.sdm.go.kr/360vr/05_indepen_fort/index.html',
            thumbnail: HistoryHall,
            category: 'Homepage',
        },
        {
            id: 3,
            name: '역사관 어제와 오늘',
            url: 'https://sphh.sscmc.or.kr/hall/hallview_01.php',
            thumbnail: HistoryHallMap,
            category: 'Homepage',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/sphh1908/',
            urlId: 'sphh1908',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/sdmprison',
            urlId: 'sdmprison',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCqBEkHNlmWwYa4B_vdW40Lw',
            urlId: 'channel/UCqBEkHNlmWwYa4B_vdW40Lw',
        },
        {
            category: 'Homepage',
            url: 'https://sphh.sscmc.or.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [
        {
            image: SphhFeedImage1,
            name: 'SphhFeedImage1',
        },
        {
            image: SphhFeedImage2,
            name: 'SphhFeedImage2',
        },
        {
            image: SphhFeedImage3,
            name: 'SphhFeedImage3',
        },
        {
            image: SphhFeedImage4,
            name: 'SphhFeedImage4',
        },
        {
            image: SphhFeedImage5,
            name: 'SphhFeedImage5',
        },
        {
            image: SphhFeedImage6,
            name: 'SphhFeedImage6',
        },
    ],
};

export const dongwonLinkList = {
    profile: {
        dongwonLogo: dongwonImageLogo,
        nickname: 'dongwonfnb',
    },
    theme: {
        backgroundImage: dongwomBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '동원몰',
            url: 'https://www.dongwonmall.com/',
            thumbnail: dongwonMall,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '동원참치 X KBO 콜라보레이션',
            url: 'https://www.youtube.com/watch?v=LPYz7PfiU4M',
            thumbnail: dongwonKBO,
            category: 'Youtube',
            urlId: 'watch?v=LPYz7PfiU4M',
        },
        {
            id: 3,
            name: '동원의 맛 꿀팁',
            url: 'https://www.youtube.com/shorts/jMat8JFoVUE',
            thumbnail: dongwonTV,
            category: 'Youtube',
            urlId: 'shorts/jMat8JFoVUE',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/dongwonfnb_food/',
            urlId: 'dongwonfnb_food',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/dongwongroup/',
            urlId: 'dongwongroup',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/c/DongwonFnB',
            urlId: 'c/DongwonFnB',
        },
        {
            category: 'Homepage',
            url: 'https://www.dongwonfnb.com/services/index;jsessionid=64B64ED769CD0FD676B34E0DFB33A170#this',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const tonymolyLinkList = {
    profile: {
        dongwonLogo: tonymolyImageLogo,
        nickname: 'tonymoly',
    },
    theme: {
        backgroundImage: tonymolyBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '토니모리몰',
            url: 'https://www.tonystreet.com/',
            thumbnail: tonymolyMall,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '토니모리 추천템',
            url: 'https://www.youtube.com/watch?v=YG3gF0Ly2Wc',
            thumbnail: tonymolyItem,
            category: 'Youtube',
            urlId: 'watch?v=YG3gF0Ly2Wc',
        },
        {
            id: 3,
            name: '유자가 자몽했다',
            url: 'https://www.youtube.com/watch?v=SHgXOKM1MgQ',
            thumbnail: tonymolyJamong,
            category: 'Youtube',
            urlId: 'watch?v=SHgXOKM1MgQ',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/tonymoly_street/',
            urlId: 'tonymoly_street',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/tonymoly.kr',
            urlId: 'tonymoly.kr',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/tonymolykr',
            urlId: 'user/tonymolykr',
        },
        {
            category: 'Homepage',
            url: 'http://corp.tonymoly.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [
        {
            image: SphhFeedImage1,
            name: 'SphhFeedImage1',
        },
        {
            image: SphhFeedImage2,
            name: 'SphhFeedImage2',
        },
        {
            image: SphhFeedImage3,
            name: 'SphhFeedImage3',
        },
        {
            image: SphhFeedImage4,
            name: 'SphhFeedImage4',
        },
        {
            image: SphhFeedImage5,
            name: 'SphhFeedImage5',
        },
        {
            image: SphhFeedImage6,
            name: 'SphhFeedImage6',
        },
    ],
};

export const pascucciLinkList = {
    profile: {
        dongwonLogo: pascucciImageLogo,
        nickname: 'pascucci',
    },
    theme: {
        backgroundImage: passcucciBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '파스쿠찌 추천메뉴',
            url: 'https://www.youtube.com/watch?v=p2pt0VGOUd4',
            thumbnail: pascucciMall,
            category: 'Youtube',
            urlId: 'watch?v=p2pt0VGOUd4',
        },

        {
            id: 2,
            name: '이벤트 및 메뉴 소개',
            url: 'https://ggoolti.tistory.com/181',
            thumbnail: pascucciItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '파스쿠찌 매장안내',
            url: 'https://www.caffe-pascucci.co.kr/store/storeList.asp',
            thumbnail: pascucciJamong,

            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/pascucci_kr',
            urlId: 'pascucci_kr',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/pascucci1883/',
            urlId: 'pascucci1883',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCHKRIWTWjq0uzJOAm6KFHOg',
            urlId: 'channel/UCHKRIWTWjq0uzJOAm6KFHOg',
        },
        {
            category: 'Homepage',
            url: 'https://www.caffe-pascucci.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const lotteDepartmentLinkList = {
    profile: {
        dongwonLogo: lotteDepLogo,
        nickname: 'lottemall',
    },
    theme: {
        backgroundImage: lotteDepBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '오늘의 기획전',
            url: 'https://www.lotteon.com/display/planV2/planDetail/104952?mall_no=2',
            thumbnail: lotteDepFirst,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '클레드뽀 보떼 쿠션 샘플링',
            url: 'https://www.lotteon.com/event/sampling/detail?evNo=E20230616012680&dp_infw_cd=EVTE20230616012680&mall_no=2',
            thumbnail: lotteDepSecond,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '매장픽업',
            url: 'https://www.lotteon.com/display/shop/seltDpShop/13115?mall_no=2',
            thumbnail: lotteDepThird,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/lotteshopping',
            urlId: 'lotteshopping',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/LOTTEshopping/?locale=ko_KR',
            urlId: 'LOTTEshopping',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@lotteshopping',
            urlId: '@lotteshopping',
        },
        {
            category: 'Homepage',
            url: 'https://www.ellotte.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const celltrionLinkList = {
    profile: {
        dongwonLogo: celltrionLogo,
        nickname: 'celltrion',
    },
    theme: {
        backgroundImage: celltrionBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '셀트리온 회사소개',
            url: 'https://www.celltrionph.com/ko-kr/aboutus/company',
            thumbnail: celltrionFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '디지털 브로슈어',
            url: 'https://www.celltrionph.com/ko-kr/pr/brochure',
            thumbnail: celltrionSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '셀트리온 보도자료',
            url: 'https://www.celltrion.com/ko-kr/pr/newslist',
            thumbnail: celltrionThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/celltrion_official',
            urlId: 'celltrion_official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/CelltrionDream',
            urlId: 'CelltrionDream',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@celltrion_official',
            urlId: '@celltrion_official',
        },
        {
            category: 'Homepage',
            url: 'https://www.celltrionph.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const samsungLinkList = {
    profile: {
        dongwonLogo: samsungLogo,
        nickname: 'samsung',
    },
    theme: {
        backgroundImage: samsungBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '삼성닷컴 새출발 세일',
            url: 'https://www.samsung.com/sec/event/april-festival/?anc=benefit',
            thumbnail: samsungFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '삼성닷컴 회원 혜택',
            url: 'https://www.samsung.com/sec/eventList/benefitzone/?anc=point',
            thumbnail: samsungSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '스토어 상담 예약',
            url: 'https://www.samsung.com/sec/customer/reservationstore/',
            thumbnail: samsungThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/samsungkorea',
            urlId: 'samsungkorea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/SamsungKorea',
            urlId: 'SamsungKorea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@SamsungKorea',
            urlId: '@SamsungKorea',
        },
        {
            category: 'Homepage',
            url: 'https://www.samsung.com/sec/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const alphaLinkList = {
    profile: {
        dongwonLogo: alphaLogo,
        nickname: 'alpha',
    },
    theme: {
        backgroundImage: alphaBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '모나미 프로모션',
            url: 'https://www.alpha.co.kr/plan/planProductForm.do?PS_IDX=2153',
            thumbnail: alphaFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '문구도매',
            url: 'https://www.alpha.co.kr/indexMungudoMae.do',
            thumbnail: alphaSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '대량 견적 서비스',
            url: 'https://www.alpha.co.kr/customer/serviceInfo/massQuote/massQuoteInfo.do',
            thumbnail: alphaThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/alphamungu/',
            urlId: 'alphamungu',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/alpha1971',
            urlId: 'alpha1971',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@-art2513',
            urlId: '@-art2513',
        },
        {
            category: 'Homepage',
            url: 'https://www.alpha.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const cgvLinkList = {
    profile: {
        dongwonLogo: cgvLogo,
        nickname: 'cgv',
    },
    theme: {
        backgroundImage: cgvBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '어벤져스  엔드게임',
            url: 'https://moviestory.cgv.co.kr/fanpage/mainView;jsessionid=2AB5C137B527ECE42E9ABDF8BA732F98.STORY_node?movieIdx=81749',
            thumbnail: cgvFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: 'CGV 우리카드 출시',
            url: 'http://www.cgv.co.kr/culture-event/event/detailViewUnited.aspx?seq=33682&menu=006',
            thumbnail: cgvSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '슈퍼마리오 브라더스 스페셜 콤보',
            url: 'http://www.cgv.co.kr/culture-event/event/detailViewUnited.aspx?seq=37041&menu=001',
            thumbnail: cgvThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/cgv_korea/?hl=ko',
            urlId: 'cgv_korea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/CJCGV/?locale=ko_KR',
            urlId: 'CJCGV',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@CGVKOREA',
            urlId: '@CGVKOREA',
        },
        {
            category: 'Homepage',
            url: 'https://www.cgv.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const cjLogisticsLinkList = {
    profile: {
        dongwonLogo: cgvLogo,
        nickname: 'cgv',
    },
    theme: {
        backgroundImage: cgvBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '어벤져스  엔드게임',
            url: 'https://moviestory.cgv.co.kr/fanpage/mainView;jsessionid=2AB5C137B527ECE42E9ABDF8BA732F98.STORY_node?movieIdx=81749',
            thumbnail: cgvFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: 'CGV 우리카드 출시',
            url: 'http://www.cgv.co.kr/culture-event/event/detailViewUnited.aspx?seq=33682&menu=006',
            thumbnail: cgvSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '슈퍼마리오 브라더스 스페셜 콤보',
            url: 'http://www.cgv.co.kr/culture-event/event/detailViewUnited.aspx?seq=37041&menu=001',
            thumbnail: cgvThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/cgv_korea/?hl=ko',
            urlId: 'cgv_korea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/CJCGV/?locale=ko_KR',
            urlId: 'CJCGV',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@CGVKOREA',
            urlId: '@CGVKOREA',
        },
        {
            category: 'Homepage',
            url: 'https://www.cgv.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const hyundaiLinkList = {
    profile: {
        dongwonLogo: hyundaiLogo,
        nickname: 'hyundai',
    },
    theme: {
        backgroundImage: hyundaiBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '쏘나타 디 엣지 사전계약 이벤트',
            url: 'https://www.hyundai.com/kr/ko/event/event-sonata-the-edge-pre-contract-2023.html',
            thumbnail: hyundaiFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '시승 신청',
            url: 'https://www.hyundai.com/kr/ko/e/vehicles/test-driving',
            thumbnail: hyundaiSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '현대Shop',
            url: 'https://shop.hyundai.com/',
            thumbnail: hyundaiThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/about_hyundai/',
            urlId: 'about_hyundai',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/AboutHyundai',
            urlId: 'AboutHyundai',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/AboutHyundai',
            urlId: 'AboutHyundai',
        },
        {
            category: 'Homepage',
            url: 'https://www.hyundai.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const interparkTicketLinkList = {
    profile: {
        dongwonLogo: interparkticketLogo,
        nickname: 'interparkticket',
    },
    theme: {
        backgroundImage: interparkticketBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '현대카드 브루노 마스 콘서트',
            url: 'https://tickets.interpark.com/goods/23005708',
            thumbnail: interparkticketFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: 'NOL 카드 혜택',
            url: 'https://nol.interpark.com/promotion/nol-promotion?mchtNo=INTERPARK_TOUR&mchtDtlNo=00&eventCode=NOIWR',
            thumbnail: interparkticketSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '티켓 위클리',
            url: 'http://ticket.interpark.com/promotion/event/eventInfo.asp',
            thumbnail: interparkticketThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/interparkticket/',
            urlId: 'interparkticket',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/interparkticket1?locale=ko_KR',
            urlId: 'interparkticket1',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@interpark_ticket',
            urlId: '@interpark_ticket',
        },
        {
            category: 'Homepage',
            url: 'http://ticket.interpark.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const isaacToastLinkList = {
    profile: {
        dongwonLogo: isaactoastLogo,
        nickname: 'isaactoast',
    },
    theme: {
        backgroundImage: isaactoastBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '마라토스트 할인이벤트',
            url: 'https://www.isaac-toast.co.kr/news/promotion.html?bmain=view&uid=53&mode=&event_state=1',
            thumbnail: isaactoastFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '이삭토스트 메뉴',
            url: 'https://www.isaac-toast.co.kr/menu/menu.html?depth1=1\n',
            thumbnail: isaactoastSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '핑크퐁 스티커 증정 이벤트',
            url: 'https://www.isaac-toast.co.kr/news/promotion.html?bmain=view&uid=54&mode=&event_state=1',
            thumbnail: isaactoastThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/isaactoast.official',
            urlId: 'isaactoast.official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/isaactoast.online',
            urlId: 'isaactoast.online',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/watch?v=qVOMWyHJx98',
            urlId: 'watch?v=qVOMWyHJx98',
        },
        {
            category: 'Homepage',
            url: 'https://www.isaac-toast.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const govLinkList = {
    profile: {
        dongwonLogo: goverment24Logo,
        nickname: 'gov24',
    },
    theme: {
        backgroundImage: goverment24BG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '정부24 회원 2천만명 돌파 이벤트',
            url: 'https://blog.naver.com/korea_gov/223078142840',
            thumbnail: goverment24FirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '정부 혜택',
            url: 'https://www.gov.kr/portal/rcvfvrSvc/svcFind/svcSearchAll',
            thumbnail: goverment24SecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '제7회 소비자지향성 개선과제 공모전',
            url: 'https://www.gov.kr/portal/cnstexhb/19888?Mcode=11184',
            thumbnail: goverment24ThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //     category: 'Instagram',
        //     url: 'https://www.instagram.com/cgv_korea/?hl=ko',
        //     urlId: 'cgv_korea',
        // },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/govkorea',
            urlId: 'govkorea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@gov24',
            urlId: '@gov24',
        },
        {
            category: 'Homepage',
            url: 'https://www.gov.kr/portal/main',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const shinMyung300001LinkList = {
    profile: {
        dongwonLogo: shinmyungLogo,
        nickname: '300001',
    },
    theme: {
        backgroundImage: shinmyungBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '회사 소개',
            url: 'http://www.smbolt.com/bbs/content.php?co_id=m1_1',
            thumbnail: shinmyungSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '제품 소개',
            url: 'http://www.smbolt.com/bbs/board.php?bo_table=m2_1',
            thumbnail: shinmyungFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '고비즈코리아 바로가기',
            url: ' https://www.gobizkorea.com/user/main.do',
            thumbnail: shinmyungThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //     category: 'Instagram',
        //     url: 'https://www.instagram.com/cgv_korea/?hl=ko',
        //     urlId: 'cgv_korea',
        // },
        // {
        //     category: 'Facebook',
        //     url: 'https://www.facebook.com/govkorea',
        //     urlId: 'govkorea',
        // },
        {
            category: 'Youtube',
            url: 'https://youtube.com/shorts/kMoF0ZoaurU?feature=share',
            urlId: 'shorts/kMoF0ZoaurU?feature=share',
        },
        {
            category: 'Homepage',
            url: 'http://www.smbolt.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const shinMyung300002LinkList = {
    profile: {
        dongwonLogo: shinmyungLogo,
        nickname: '300002',
    },
    theme: {
        backgroundImage: shinmyungBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '회사 소개',
            url: 'http://www.smbolt.com/bbs/content.php?co_id=m1_1',
            thumbnail: shinmyungSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '제품 소개',
            url: 'http://www.smbolt.com/bbs/board.php?bo_table=m2_1',
            thumbnail: shinmyungFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '고비즈코리아 바로가기',
            url: ' https://www.gobizkorea.com/user/main.do',
            thumbnail: shinmyungThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //     category: 'Instagram',
        //     url: 'https://www.instagram.com/cgv_korea/?hl=ko',
        //     urlId: 'cgv_korea',
        // },
        // {
        //     category: 'Facebook',
        //     url: 'https://www.facebook.com/govkorea',
        //     urlId: 'govkorea',
        // },
        {
            category: 'Youtube',
            url: 'https://youtube.com/shorts/kMoF0ZoaurU?feature=share',
            urlId: 'shorts/kMoF0ZoaurU?feature=share',
        },
        {
            category: 'Homepage',
            url: 'http://www.smbolt.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const shinMyungLinkList = {
    profile: {
        dongwonLogo: shinmyungLogo,
        nickname: 'SHINMYUNG',
    },
    theme: {
        backgroundImage: shinmyungBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '회사 소개',
            url: 'http://www.smbolt.com/bbs/content.php?co_id=m1_1',
            thumbnail: shinmyungSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '제품 소개',
            url: 'http://www.smbolt.com/bbs/board.php?bo_table=m2_1',
            thumbnail: shinmyungFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '고비즈코리아 바로가기',
            url: ' https://www.gobizkorea.com/user/main.do',
            thumbnail: shinmyungThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //     category: 'Instagram',
        //     url: 'https://www.instagram.com/cgv_korea/?hl=ko',
        //     urlId: 'cgv_korea',
        // },
        // {
        //     category: 'Facebook',
        //     url: 'https://www.facebook.com/govkorea',
        //     urlId: 'govkorea',
        // },
        {
            category: 'Youtube',
            url: 'https://youtube.com/shorts/kMoF0ZoaurU?feature=share',
            urlId: 'shorts/kMoF0ZoaurU?feature=share',
        },
        {
            category: 'Homepage',
            url: 'http://www.smbolt.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const gleammediaLinkList = {
    profile: {
        dongwonLogo: gleamLogo,
        nickname: '300001',
    },
    theme: {
        backgroundImage: gleamBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '글림미디어 아티스트 소개',
            url: 'https://www.gleammedia.co.kr/%EB%B3%B5%EC%A0%9C-business',
            thumbnail: gleamFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '스타플레이 샵',
            url: 'https://starplay.shop/',
            thumbnail: gleamSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '뱀뱀 X IRYUK',
            url: 'https://starplay.shop/BAMBAM_x_IRYUK',
            thumbnail: gleamThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/starplay_official/',
            urlId: 'starplay_official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/mystarplay/',
            urlId: 'mystarplay',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@starplay_official',
            urlId: '@starplay_official',
        },
        {
            category: 'Homepage',
            url: 'https://www.gleammedia.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};


export const samyangLinkList = {
    profile: {
        dongwonLogo: samyangLogo,
        nickname: 'samyang',
    },
    theme: {
        backgroundImage: samyangBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '삼양 역사',
            url: 'https://www.samyangfoods.com/kor/publicity/history/index.do',
            thumbnail: samyangFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '홍보 영상',
            url: 'https://www.samyangfoods.com/kor/publicity/public/list.do',
            thumbnail: samyangSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '삼양식품 공식몰',
            url: 'https://brand.naver.com/syfoodshop',
            thumbnail: samyangThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/samyangfoods',
            urlId: 'samyangfoods',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/samyangfoods',
            urlId: 'samyangfoods',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@samyangfoods',
            urlId: '@samyangfoods',
        },
        {
            category: 'Homepage',
            url: 'https://www.samyangfoods.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const amorepacificLinkList = {
    profile: {
        dongwonLogo: amorepacificLogo,
        nickname: 'amorepacific',
    },
    theme: {
        backgroundImage: amorepacificBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '마몽드 제품',
            url: 'https://www.mamonde.com/',
            thumbnail: amorepacificFirstItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '핫썸머 세일',
            url: 'https://www.amoremall.com/kr/ko/display/event_detail?planDisplaySn=8838',
            thumbnail: amorepacificSecondItem,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '헤라 증정이벤트',
            url: 'https://www.amoremall.com/kr/ko/display/event_detail?planDisplaySn=8682',
            thumbnail: amorepacificThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/amorepacific.official',
            urlId: 'amorepacific.official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/amorepacific.official',
            urlId: 'amorepacific.official',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@AmorepacificGroup',
            urlId: '@AmorepacificGroup',
        },
        {
            category: 'Homepage',
            url: 'https://www.amoremall.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};



export const louisquatorzeLinkList = {
    profile: {
        dongwonLogo: louisquatorzeImageLogo,
        nickname: 'louis quatorze',
    },
    theme: {
        backgroundImage: louisquatorzeBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '루이까또즈 브랜드 역사',
            url: 'https://www.youtube.com/watch?v=uqBIX-_90lM',
            thumbnail: louisquatorzeHistory,
            category: 'Youtube',
            urlId: 'watch?v=uqBIX-_90lM',
        },

        {
            id: 2,
            name: '루이까또즈 추천상품',
            url: 'https://pink29ana.com/%EB%A3%A8%EC%9D%B4%EA%B9%8C%EB%98%90%EC%A6%88%EA%B0%80%EB%B0%A9-%EC%B6%94%EC%B2%9C-%EC%88%9C%EC%9C%84-top10/',
            thumbnail: louisquatorzeProduct,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '루이까또즈 프로모션',
            url: 'https://www.stylelq.com/fr/louissquare/promotionList.do?cate=P',
            thumbnail: louisquatorzePromotion,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/louisquatorze_official/',
            urlId: 'louisquatorze_official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/louisquatorze.kr/',
            urlId: 'louisquatorze.kr',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCVC-36rbSkODS-gbVijTS-w',
            urlId: 'channel/UCVC-36rbSkODS-gbVijTS-w',
        },
        {
            category: 'Homepage',
            url: 'https://www.stylelq.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const samlipsaladLinkList = {
    profile: {
        dongwonLogo: samlipImageLogo,
        nickname: 'samlip',
    },
    theme: {
        backgroundImage: samlipBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '삼립몰',
            url: 'https://brand.naver.com/samlip',
            thumbnail: samlipMall,
            category: 'Homepage',
            urlId: 'null',
        },

        {
            id: 2,
            name: '삼립 스쿱샐러드 활용 레시피',
            url: 'https://www.youtube.com/watch?v=gDEv2PXULd0',
            thumbnail: samlipSalad,
            category: 'Youtube',
            urlId: 'watch?v=gDEv2PXULd0',
        },

        {
            id: 3,
            name: '삼립 스쿱샐러드 후기',
            url: 'https://blog.naver.com/lovelydl22/222438206943',
            thumbnail: sanlipScoupSalad,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/p/CCBAIv4lMyr/',
            urlId: 'CCBAIv4lMyr',
        },
        {
            category: 'Facebook',
            url: 'https://ko-kr.facebook.com/samlipgf/',
            urlId: 'samlipgf',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UC88W-nkd_7G9S7u-2xlX4DQ',
            urlId: 'channel/UC88W-nkd_7G9S7u-2xlX4DQ',
        },
        {
            category: 'Homepage',
            url: 'https://spcsamlip.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const bebecookLinkList = {
    profile: {
        dongwonLogo: bebecookImageLogo,
        nickname: 'bebecook',
    },
    theme: {
        backgroundImage: bebecookBG,
        themeName: 'dongwon',
        font: 'CookieRun-Regular',
    },
    list: [
        {
            id: 1,
            name: '순살연어구이 후기',
            url: 'https://blog.naver.com/yjyj7595/222391774098',
            thumbnail: bebeReview,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '어린이에게도 좋은 연어',
            url: 'https://choodamedu.tistory.com/entry/%EC%97%B0%EC%96%B4-%ED%9A%A8%EB%8A%A5-%EC%97%AC%EC%A0%84%ED%9E%88-%EB%86%80%EB%9D%BC%EC%9A%B4-12%EA%B0%80%EC%A7%80',
            thumbnail: bebeYean,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 3,
            name: '물어베베 #연령별섭취요령',
            url: 'https://www.youtube.com/watch?v=PKjasLy_IdU',
            thumbnail: bebeContack,
            category: 'Youtube',
            urlId: 'watch?v=PKjasLy_IdU',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/bebecook/',
            urlId: 'bebecook',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/bebecook1999/',
            urlId: 'bebecook1999',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCYDzuw4d0eH998fFjHbHokQ',
            urlId: 'channel/UCYDzuw4d0eH998fFjHbHokQ',
        },
        {
            category: 'Homepage',
            url: 'https://www.bebecook.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const bbqLinkList = {
    profile: {
        dongwonLogo: bbqImageLogo,
        nickname: 'bbq',
    },
    theme: {
        backgroundImage: bbqBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'bbq 몰',
            url: 'https://mall.bbq.co.kr/goods/view?no=62#goods_exchange_guide',
            thumbnail: bbqMall,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: 'bbq 삼계탕 후기',
            url: 'https://blog.naver.com/haesay0414/222463226258',
            thumbnail: bbqReview,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'bbq Top3 치킨',
            url: 'https://www.youtube.com/watch?v=P8bdatg4eHQ',
            thumbnail: bbqEfficacy,
            category: 'Youtube',
            urlId: 'watch?v=uqBIX-_90lM',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/bbq_offi/',
            urlId: 'bbq_offi',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/BBQ치킨-110863401617269/',
            urlId: 'BBQ치킨-110863401617269',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/BBQMobile',
            urlId: 'user/BBQMobile',
        },
        {
            category: 'Homepage',
            url: 'https://www.bbq.co.kr/main.asp',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const opriceLinkList = {
    profile: {
        dongwonLogo: opriceImageLogo,
        nickname: 'oprice',
    },
    theme: {
        backgroundImage: opriceBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '오프라이스의 모든 것',
            url: 'https://m-kimsclub.elandmall.com/shop/initPlanShop.action?disp_ctg_no=2004442078',
            thumbnail: opriceInfo,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '노브랜O보다 가성비 좋은곳',
            url: 'https://www.youtube.com/watch?v=0AhBM2SbdEY',
            thumbnail: opriceNobrand,
            category: 'Youtube',
            urlId: 'watch?v=0AhBM2SbdEY',
        },
        {
            id: 3,
            name: '오프라이스 4종 먹방!',
            url: 'https://www.youtube.com/watch?v=4iMZbHLre6U',
            thumbnail: opriceMukbang,
            category: 'Youtube',
            urlId: 'watch?v=4iMZbHLre6U',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/oprice_official/',
            urlId: 'oprice_official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/오프라이스-576680179391346',
            urlId: '오프라이스-576680179391346',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/elandgroup',
            urlId: 'user/elandgroup',
        },
        {
            category: 'Homepage',
            url: 'https://www.elandmall.com/dispctg/initBrandShop.action?brand_no=1900014016',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const monnaLinkList = {
    profile: {
        dongwonLogo: monnaLogo,
        nickname: 'monna',
    },
    theme: {
        backgroundImage: monnaBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '오늘의 공연 - 전시',
            url: 'https://www.sac.or.kr/site/main/show/todayShow',
            thumbnail: monnaToday,
            category: 'Homepage',
            urlId: null,
        },

        {
            id: 2,
            name: '여름 음악 축제',
            url: 'https://www.youtube.com/watch?v=7uts7LTyh5Y',
            thumbnail: monnaMusic,
            category: 'Youtube',
            urlId: 'watch?v=7uts7LTyh5Y',
        },
        {
            id: 3,
            name: '예술의 전당 방문 후기',
            url: 'https://musicalcurator.tistory.com/57',
            thumbnail: monnaReview,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/seoul_arts_center/',
            urlId: 'seoul_arts_center',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/SeoulArtsCenter.Korea',
            urlId: 'SeoulArtsCenter.Korea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCUn8h6aG6rCM1zvXKIuNpVQ',
            urlId: 'channel/UCUn8h6aG6rCM1zvXKIuNpVQ',
        },
        {
            category: 'Homepage',
            url: 'https://www.sac.or.kr/site/main/home',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const kifLinkList = {
    profile: {
        dongwonLogo: kifLogo,
        nickname: '2022kif',
    },
    theme: {
        backgroundImage: kifBG,
        themeName: 'kiff',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '2022 KIF 홈페이지',
            url: 'https://www.2022kif.com',
            thumbnail: kifNews,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '멜론티켓',
            url: 'https://ticket.melon.com',
            thumbnail: kifMelon,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '인스타그램',
            url: 'https://www.instagram.com/2022kif/',
            thumbnail: kifInstaImage,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/2022kif/',
            urlId: '2022kif',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/2022kif',
            urlId: '2022kif',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCfKQZeOwE4Ukj0-BYnmXQSw',
            urlId: 'channel/UCfKQZeOwE4Ukj0-BYnmXQSw',
        },
        {
            category: 'Homepage',
            url: 'https://www.2022kif.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const mhscLinkList = {
    profile: {
        dongwonLogo: mhscLogo,
        nickname: '2022mhsc',
    },
    theme: {
        backgroundImage: mhscBG,
        // themeName: 'mhsc',
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'MH슈퍼콘서트 X 고고90 홈페이지',
            url: 'https://mhandgogo90.modoo.at/',
            thumbnail: mhscLogo,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '티켓예매1',
            url: 'https://mticket.interpark.com/Notice/NoticeView?bn=34&no=44623',
            thumbnail: mhscTicket1,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '티켓예매2',
            url: 'https://smartstore.naver.com/smartmoms/products/7363862796',
            thumbnail: mhscTicket2,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/mh_gogo90',
            urlId: 'mh_gogo90',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/profile.php?id=100086734178263',
            urlId: 'profile.php?id=100086734178263',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/channel/UCFB6ZUKG6l6pB0wvDvxjftg',
        //   urlId: 'channel/UCFB6ZUKG6l6pB0wvDvxjftg',
        // },
        {
            category: 'Homepage',
            url: 'https://mhandgogo90.modoo.at/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const godivaList = {
    profile: {
        dongwonLogo: godivaLogo,
        nickname: 'godiva',
    },
    theme: {
        backgroundImage: godivaBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '고디바 매장 찾기',
            url: 'https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=1&ie=utf8&query=%EA%B3%A0%EB%94%94%EB%B0%94',
            thumbnail: godivaStore,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: `고디바 홈페이지`,
            url: 'https://www.godiva.kr/',
            thumbnail: godivaLogo,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '고디바 X 이스테이션 특별가 한정 판매',
            url: 'https://estation.siig.io/godiva',
            thumbnail: godivaEstation,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/godivakorea',
            urlId: 'godivakorea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/GODIVAKR',
            urlId: 'GODIVAKR',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCFB6ZUKG6l6pB0wvDvxjftg',
            urlId: 'channel/UCFB6ZUKG6l6pB0wvDvxjftg',
        },
        {
            category: 'Homepage',
            url: 'https://www.godiva.kr',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },

        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },

        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const watergList = {
    profile: {
        dongwonLogo: watergLogo,
        nickname: 'waterg',
    },
    theme: {
        backgroundImage: watergBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '워터 제네시스 홈페이지',
            url: 'https://waterg.net/index.php',
            thumbnail: watergLogo,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'A/S신청',
            url: 'https://waterg.net/sub/sub04_01.php',
            thumbnail: watergAS,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '유튜브',
            url: 'https://www.youtube.com/channel/UCNeDnCr0ZgIY-giyxW-zXmQ/featured',
            thumbnail: watergYoutube,
            category: 'Youtube',
            urlId: 'channel/UCNeDnCr0ZgIY-giyxW-zXmQ/featured',
        },
        {
            id: 4,
            name: 'ENVEX 참가 동영상',
            url: 'https://www.youtube.com/watch?v=gTfyLPNDmk4&t=55s',
            thumbnail: watergEnvex,
            category: 'Youtube',
            urlId: 'watch?v=gTfyLPNDmk4&t=55s',
        },
        {
            id: 5,
            name: '클린지니',
            url: 'https://waterg.net/sub/sub02_01.php',
            thumbnail: watergEarth,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/godivakorea',
        //   urlId: 'godivakorea',
        // },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/GODIVAKR',
        //   urlId: 'GODIVAKR',
        // },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/channel/UCFB6ZUKG6l6pB0wvDvxjftg',
        //   urlId: 'channel/UCFB6ZUKG6l6pB0wvDvxjftg',
        // },
        // {
        //   category: 'Homepage',
        //   url: 'https://www.godiva.kr',
        // },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const btsLinkList = {
    profile: {
        dongwonLogo: btsLogo,
        nickname: 'bts_guess',
    },
    theme: {
        backgroundImage: btsBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'BTS홈페이지',
            url: 'https://ibighit.com/bts/kor/',
            thumbnail: btsHome,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'BTS 인스타그램',
            url: 'https://www.instagram.com/bts.bighitofficial',
            thumbnail: btsInsta,
            category: 'Instagram',
            urlId: 'bts.bighitofficial',
        },
        {
            id: 3,
            name: 'BTS 페이스북',
            url: 'https://www.facebook.com/bangtan.official',
            thumbnail: btsFacebook,
            category: 'Facebook',
            urlId: 'bangtan.official',
        },
        {
            id: 4,
            name: 'BTS 유튜브',
            url: 'https://www.youtube.com/c/BANGTANTV',
            thumbnail: btsYoutube,
            category: 'Youtube',
            urlId: 'c/BANGTANTV',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/guess',
            urlId: 'guess',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/GUESSSouthKorea',
            urlId: 'GUESSSouthKorea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCTS9JlGeL7zeNcZZLrzD6bg',
            urlId: 'channel/UCTS9JlGeL7zeNcZZLrzD6bg',
        },
        {
            category: 'Homepage',
            url: 'https://www.guesskorea.com/main/main.php',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const isaacLinkList = {
    profile: {
        dongwonLogo: isaacLogo,
        nickname: 'isaac',
    },
    theme: {
        backgroundImage: isaacBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '주문하기',
            url: 'https://www.isaac-toast.co.kr/menu/menu.html?depth1=1',
            thumbnail: isaacOrder,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '이삭토스트 3종 먹방',
            url: 'https://www.youtube.com/watch?v=P31LenLjzZo',
            thumbnail: isaacMucbang,
            category: 'Youtube',
            urlId: 'watch?v=P31LenLjzZo',
        },
        {
            id: 3,
            name: '이벤트 프로모션',
            url: 'https://www.isaac-toast.co.kr/news/promotion.html',
            thumbnail: isaacEvent,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/isaactoast.official',
            urlId: 'isaactoast.official',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/isaactoast.online',
            urlId: 'isaactoast.online',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/channel/UCTS9JlGeL7zeNcZZLrzD6bg',
        //   urlId: 'channel/UCTS9JlGeL7zeNcZZLrzD6bg',
        // },
        {
            category: 'Homepage',
            url: 'https://www.isaac-toast.co.kr/main/main.html',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const kgcshopLinkList = {
    profile: {
        dongwonLogo: kgcshopLogo,
        nickname: 'kgcshop',
    },
    theme: {
        backgroundImage: kgcshopBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '정관장몰 베스트',
            url: 'https://www.kgcshop.co.kr/event/goodsBest?paramGnbId=30',
            thumbnail: kgcshopBest,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '정관장몰 기획전',
            url: 'https://www.kgcshop.co.kr/event/promotionList?paramGnbId=27#page1',
            thumbnail: kgcshopPromotion,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '정관장몰 이벤트',
            url: 'https://www.kgcshop.co.kr/event/goodsPromotion?dispId=5660',
            thumbnail: kgcshopEvent,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/kgc_official_',
            urlId: 'kgc_official_',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/KGCsamsamstory',
            urlId: 'KGCsamsamstory',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/samsamstory/featured',
            urlId: 'user/samsamstory/featured',
        },
        {
            category: 'Homepage',
            url: 'https://www.kgcshop.co.kr',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const kgcshop200001LinkList = {
    profile: {
        dongwonLogo: kgcshopLogo,
        nickname: '200001',
    },
    theme: {
        backgroundImage: kgcshopBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '정관장몰 베스트',
            url: 'https://www.kgcshop.co.kr/event/goodsBest?paramGnbId=30',
            thumbnail: kgcshopBest,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '정관장몰 기획전',
            url: 'https://www.kgcshop.co.kr/event/promotionList?paramGnbId=27#page1',
            thumbnail: kgcshopPromotion,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '정관장몰 이벤트',
            url: 'https://www.kgcshop.co.kr/event/goodsPromotion?dispId=5660',
            thumbnail: kgcshopEvent,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/kgc_official_',
            urlId: 'kgc_official_',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/KGCsamsamstory',
            urlId: 'KGCsamsamstory',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/samsamstory/featured',
            urlId: 'user/samsamstory/featured',
        },
        {
            category: 'Homepage',
            url: 'https://www.kgcshop.co.kr',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const kgcshop200002LinkList = {
    profile: {
        dongwonLogo: kgcshopLogo,
        nickname: '200002',
    },
    theme: {
        backgroundImage: kgcshopBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '정관장몰 베스트',
            url: 'https://www.kgcshop.co.kr/event/goodsBest?paramGnbId=30',
            thumbnail: kgcshopBest,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '정관장몰 기획전',
            url: 'https://www.kgcshop.co.kr/event/promotionList?paramGnbId=27#page1',
            thumbnail: kgcshopPromotion,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '정관장몰 이벤트',
            url: 'https://www.kgcshop.co.kr/event/goodsPromotion?dispId=5660',
            thumbnail: kgcshopEvent,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/kgc_official_',
            urlId: 'kgc_official_',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/KGCsamsamstory',
            urlId: 'KGCsamsamstory',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/samsamstory/featured',
            urlId: 'user/samsamstory/featured',
        },
        {
            category: 'Homepage',
            url: 'https://www.kgcshop.co.kr',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const incelldermLinkList = {
    profile: {
        dongwonLogo: incelldermLinkLogo,
        nickname: 'incellderm_official',
    },
    theme: {
        backgroundImage: incelldermBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '회원가입',
            url: 'https://myoffice.rimankorea.com/member/login',
            thumbnail: incelldermSignup,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '인셀덤 카탈로그',
            url: 'https://incellderm.rimankorea.com/products\n',
            thumbnail: incelldermCatalog,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '인셀덤 성공사례',
            url: 'https://www.youtube.com/watch?v=sxT2hveIyiQ',
            thumbnail: incelldermYoutube,
            category: 'Youtube',
            urlId: 'watch?v=sxT2hveIyiQ',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/incellderm.official',
            urlId: 'incellderm.official',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/KGCsamsamstory',
        //   urlId: 'KGCsamsamstory',
        // },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@incellderm2166',
            urlId: '@incellderm2166',
        },
        {
            category: 'Homepage',
            url: 'https://incellderm.rimankorea.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const chanelLinkList = {
    profile: {
        dongwonLogo: chanelLogo,
        nickname: 'chanel',
    },
    theme: {
        backgroundImage: chanelBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '샤넬 브랜드 역사',
            url: 'https://www.chanel.com/kr/about-chanel/the-history/',
            thumbnail: chanelHistory,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '샤넬 추천 상품',
            url: 'https://www.chanel.com/kr/high-jewelry/',
            thumbnail: chanelProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '샤넬 프로모션',
            url: 'https://www.chanel.com/kr/haute-couture/',
            thumbnail: chanelPromotion,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/chanelofficial',
            urlId: 'chanelofficial',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/ChanelKR',
            urlId: 'ChanelKR',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@CHANEL',
            urlId: '@CHANEL',
        },
        {
            category: 'Homepage',
            url: 'https://www.chanel.com/kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const lotteShoppingLinkList = {
    profile: {
        dongwonLogo: lotteShoppingLogo,
        nickname: 'lotteshopping',
    },
    theme: {
        backgroundImage: lotteShoppingBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '상품권 구입안내',
            url: 'https://www.lotteshopping.com/service/voucherBuy',
            thumbnail: lotteShoppingBuy,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '상품권 권종',
            url: 'https://www.lotteshopping.com/service/voucher',
            thumbnail: lotteShoppingList,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '상품권 사용안내',
            url: 'https://www.lotteshopping.com/service/voucherUse',
            thumbnail: lotteShoppingInfo,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/lotteshopping',
            urlId: 'lotteshopping',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/LOTTEshopping',
            urlId: 'LOTTEshopping',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCpBkdV5sGB9v80H8XTewtcg',
            urlId: 'channel/UCpBkdV5sGB9v80H8XTewtcg',
        },
        {
            category: 'Homepage',
            url: 'https://www.lotteshopping.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const pfizerShoppingLinkList = {
    profile: {
        dongwonLogo: pfzerLogo,
        nickname: 'pfizer',
    },
    theme: {
        backgroundImage: pfzerBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '회사 소개',
            url: 'https://www.pfizer.co.kr/about-us/기업-소개',
            thumbnail: pfzerCompany,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '새로운 소식',
            url: 'https://www.pfizer.co.kr/media/화이자-소식',
            thumbnail: pfzerNew,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '한국화이자제약 제품',
            url: 'https://www.pfizer.co.kr/products-list',
            thumbnail: pfzerProduct,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/',
        //   urlId: '',
        // },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/Pfizer',
            urlId: 'Pfizer',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/',
        //   urlId: '',
        // },
        {
            category: 'Homepage',
            url: 'https://www.pfizer.co.kr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const mamondeLinkList = {
    profile: {
        dongwonLogo: mamondeLogo,
        nickname: 'mamonde',
    },
    theme: {
        backgroundImage: mamondeBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '베스트 & 신제품',
            url: 'https://www.mamonde.com/kr/ko/product/bestnew/index.html',
            thumbnail: mamondeBest,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '스킨케어 전체보기',
            url: 'https://www.mamonde.com/kr/ko/product/skincare/category/all/index.html',
            thumbnail: mamondeSkin,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '메이크업 전체보기',
            url: 'https://www.mamonde.com/kr/ko/product/makeup/category/all/index.html',
            thumbnail: mamondeMakeUp,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/mamondekorea',
            urlId: 'mamondekorea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/mamonde.kr',
            urlId: 'mamonde.kr',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/mamonderepair',
            urlId: 'user/mamonderepair',
        },
        {
            category: 'Homepage',
            url: 'https://www.mamonde.com/kr/ko/index.html',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const spicyRamenLinkList = {
    profile: {
        dongwonLogo: spicyRamenLogo,
        nickname: 'spicyRamen',
    },
    theme: {
        backgroundImage: spicyRamenBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '불닭볶음면의 소개와 종류',
            url: 'https://www.samyangfoods.com/kor/brand/spicyRamen.do',
            thumbnail: spicyRamenProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '삼양라면 브랜드 소개',
            url: 'https://www.samyangfoods.com/kor/brand/samyangRamen.do',
            thumbnail: spicyRamenBrand,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '고객 지원',
            url: 'https://www.samyangfoods.com/kor/content/sound/index.do',
            thumbnail: spicyRamenContact,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/samyangfoods',
            urlId: 'samyangfoods',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/samyangfoods',
            urlId: 'samyangfoods',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/samyangfoods',
            urlId: 'samyangfoods',
        },
        {
            category: 'Homepage',
            url: 'https://www.samyangfoods.com/kor/index.do',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const samsungBudsLinkList = {
    profile: {
        dongwonLogo: samsungBudsLogo,
        nickname: 'samsung_buds',
    },
    theme: {
        backgroundImage: samsungBudsBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '갤럭시 버즈2 프로',
            url: 'https://www.samsung.com/sec/buds/all-buds/?galaxy-buds2-pro',
            thumbnail: samsungBuds2,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '갤럭시 버즈 프로',
            url: 'https://www.samsung.com/sec/buds/all-buds/?galaxy-buds-pro',
            thumbnail: samsungBuds,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '스페셜 에디션',
            url: 'https://www.samsung.com/sec/buds/all-buds/?buds-special-edition',
            thumbnail: samsungEdition,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/samsungkorea',
            urlId: 'samsungkorea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/SamsungKorea',
            urlId: 'SamsungKorea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/c/samsungkorea',
            urlId: 'c/samsungkorea',
        },
        {
            category: 'Homepage',
            url: 'https://www.samsung.com/sec/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const penmaxLinkList = {
    profile: {
        dongwonLogo: penmaxLogo,
        nickname: 'penmax',
    },
    theme: {
        backgroundImage: penmaxBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '제품 보기',
            url: 'http://www.seoheung.co.kr/home/sub/official_pgildan',
            thumbnail: penmaxProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '공지 사항',
            url: 'http://www.seoheung.co.kr/home/sub/bbs_notice',
            thumbnail: penmaxNotice,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '고객 지원',
            url: 'http://www.seoheung.co.kr/home/sub/bbs_qna',
            thumbnail: penmaxCantact,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/',
        //   urlId: '',
        // },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/',
        //   urlId: '',
        // },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/',
        //   urlId: '',
        // },
        {
            category: 'Homepage',
            url: 'http://www.seoheung.co.kr/home/info/1980',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const marvelLinkList = {
    profile: {
        dongwonLogo: marvelLogo,
        nickname: 'marvel',
    },
    theme: {
        backgroundImage: marvelBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'MOVIES',
            url: 'https://www.marvel.com/movies',
            thumbnail: marvelMovies,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'COMICS',
            url: 'https://www.marvel.com/comics',
            thumbnail: marvelComics,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'NEWS',
            url: 'https://www.marvel.com/articles',
            thumbnail: marvelNews,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/marvel',
            urlId: 'marvel',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/Marvel',
            urlId: 'Marvel',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/marvel',
            urlId: 'marvel',
        },
        {
            category: 'Homepage',
            url: 'https://www.marvel.com/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const cjlogisticsLinkList = {
    profile: {
        dongwonLogo: cjlogisticsLogo,
        nickname: 'cjlogistics',
    },
    theme: {
        backgroundImage: cjlogisticsBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '오네 서비스',
            url: 'https://www.cjlogistics.com//ko/o-ne#none',
            thumbnail: cjlogisticsFirstItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '보도 자료',
            url: 'https://www.cjlogistics.com/ko/newsroom/news',
            thumbnail: cjlogisticsSecondItem,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '배송 조회',
            url: 'https://www.cjlogistics.com/ko/tool/parcel/tracking',
            thumbnail: cjlogisticsThirdItem,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/cjlogistics.official/?hl=ko',
            urlId: 'cjlogistics.official/?hl=ko',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/CJLogisticsglobal',
            urlId: 'CJLogisticsglobal',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/cjlogistics',
            urlId: 'cjlogistics',
        },
        {
            category: 'Homepage',
            url: 'https://www.cjlogistics.com/ko/main',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const mobisLinkList = {
    profile: {
        dongwonLogo: mobisLogo,
        nickname: 'mobis',
    },
    theme: {
        backgroundImage: mobisBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '현대 모비스 소개',
            url: 'https://www.mobis.co.kr/kr/aboutus/aboutus.do#introduce',
            thumbnail: mobisInfo,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '인재 채용',
            url: 'https://www.mobis.co.kr/kr/aboutus/careers.do',
            thumbnail: mobisCareer,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '뉴스룸',
            url: 'https://www.mobis.co.kr/kr/aboutus/press.do',
            thumbnail: mobisNews,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/',
        //   urlId: '',
        // },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/mobisworld',
            urlId: 'mobisworld',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/',
        //   urlId: '',
        // },
        {
            category: 'Homepage',
            url: 'https://www.mobis.co.kr/kr/index.do',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const vangoghmuseumLinkList = {
    profile: {
        dongwonLogo: vangoghmuseumLogo,
        nickname: 'vangoghmuseum',
    },
    theme: {
        backgroundImage: vangoghmuseumBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'Tickets & Visit',
            url: 'https://www.vangoghmuseum.nl/en/visit/tickets-and-ticket-prices',
            thumbnail: vangoghmuseumTickets,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'Art & Stories',
            url: 'https://www.vangoghmuseum.nl/en/art-and-stories/art\n',
            thumbnail: vangoghmuseumArt,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Webstore',
            url: 'https://www.vangoghmuseumshop.com/en/shop',
            thumbnail: vangoghmuseumWebstore,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/',
        //   urlId: '',
        // },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/',
        //   urlId: '',
        // },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/',
        //   urlId: '',
        // },
        {
            category: 'Homepage',
            url: 'https://www.vangoghmuseum.nl/nl',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const muzikLinkList = {
    profile: {
        dongwonLogo: muzikLogo,
        nickname: 'muzik',
    },
    theme: {
        backgroundImage: muzikBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '이벤트',
            url: 'https://ra.co/events/kr/seoul',
            thumbnail: muzikEvent,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '음악',
            url: 'https://ra.co/music',
            thumbnail: muzikMusic,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '매거진',
            url: 'https://ra.co/magazine',
            thumbnail: muzikMagazin,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/resident_advisor',
            urlId: 'resident_advisor',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/residentadvisor',
            urlId: 'residentadvisor',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/residentadvisor',
            urlId: 'residentadvisor',
        },
        {
            category: 'Homepage',
            url: 'https://ra.co/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const louvreLinkList = {
    profile: {
        dongwonLogo: louvreLogo,
        nickname: 'louvre',
    },
    theme: {
        backgroundImage: louvreBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'VISITER',
            url: 'https://www.louvre.fr/visiter',
            thumbnail: louvreVisiter,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'DÉCOUVRIR',
            url: 'https://www.louvre.fr/decouvrir',
            thumbnail: louvreDecouvrir,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'EN CE MOMENT',
            url: 'https://www.louvre.fr/en-ce-moment',
            thumbnail: louvreEnceMoment,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/museelouvre',
            urlId: '',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/museedulouvre',
            urlId: '',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/user/louvre',
            urlId: '',
        },
        {
            category: 'Homepage',
            url: 'https://www.louvre.fr/',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const gwanakLinkList = {
    profile: {
        dongwonLogo: gwanakLogo,
        nickname: 'gwanak',
    },
    theme: {
        backgroundImage: gwanakBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '관악 소개',
            url: 'https://www.gwanak.go.kr/site/gwanak/11/11101010000002016051207.jsp',
            thumbnail: gwanakInfo,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '참여 / 예약',
            url: 'https://www.gwanak.go.kr/site/gwanak/02/10201030100002016051202.jsp',
            thumbnail: gwanakSave,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '민원 접수',
            url: 'https://www.gwanak.go.kr/site/gwanak/ex/civilManual/CivilManualFList.do',
            thumbnail: gwanakContact,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/',
        //   urlId: '',
        // },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/',
        //   urlId: '',
        // },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/',
        //   urlId: '',
        // },
        {
            category: 'Homepage',
            url: 'https://www.gwanak.go.kr/site/gwanak/main.do',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const cartier100001LinkList = {
    profile: {
        dongwonLogo: cartierWineLogo,
        nickname: '100001',
    },
    theme: {
        backgroundImage: cartierWineBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'Commercial',
            url: 'https://www.youtube.com/watch?v=kVXm9JOl1XA',
            thumbnail: cartierWineBell,
            category: 'Youtube',
            urlId: 'watch?v=kVXm9JOl1XA',
        },
        {
            id: 2,
            name: 'Fake VS. Real',
            url: 'https://www.swisswatchexpo.com/TheWatchClub/2019/02/08/how-to-spot-a-fake-cartier-watch/',
            thumbnail: cartierWineEye,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Unboxing',
            url: 'https://www.youtube.com/watch?v=2gxAbVchU8Q',
            thumbnail: cartierWineBoxing,
            category: 'Youtube',
            urlId: 'watch?v=2gxAbVchU8Q',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/cartier',
            urlId: 'cartier',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/cartier.korea/?brand_redir=203621737645',
            urlId: 'cartier.korea/?brand_redir=203621737645',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/cartier',
            urlId: 'cartier',
        },
        {
            category: 'Homepage',
            url: 'https://www.cartier.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const cartier100002LinkList = {
    profile: {
        dongwonLogo: cartierWineLogo,
        nickname: '100002',
    },
    theme: {
        backgroundImage: cartierWineBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'Commercial',
            url: 'https://www.youtube.com/watch?v=kVXm9JOl1XA',
            thumbnail: cartierWineBell,
            category: 'Youtube',
            urlId: 'watch?v=kVXm9JOl1XA',
        },
        {
            id: 2,
            name: 'Fake VS. Real',
            url: 'https://www.swisswatchexpo.com/TheWatchClub/2019/02/08/how-to-spot-a-fake-cartier-watch/',
            thumbnail: cartierWineEye,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Unboxing',
            url: 'https://www.youtube.com/watch?v=2gxAbVchU8Q',
            thumbnail: cartierWineBoxing,
            category: 'Youtube',
            urlId: 'watch?v=2gxAbVchU8Q',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/cartier',
            urlId: 'cartier',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/cartier.korea/?brand_redir=203621737645',
            urlId: 'cartier.korea/?brand_redir=203621737645',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/cartier',
            urlId: 'cartier',
        },
        {
            category: 'Homepage',
            url: 'https://www.cartier.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const cartier000001LinkList = {
    profile: {
        dongwonLogo: cartierGrayLogo,
        nickname: '000001',
    },
    theme: {
        backgroundImage: cartierGrayBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'Commercial',
            url: 'https://www.youtube.com/watch?v=SSnVP-X_CLc',
            thumbnail: cartierGrayBell,
            category: 'Youtube',
            urlId: 'watch?v=SSnVP-X_CLc',
        },
        {
            id: 2,
            name: 'Fake VS. Real',
            url: 'https://legitcheck.app/guides/watches/iwc/real-vs-fake-iwc-portuguese-pilot-portofino/',
            thumbnail: cartierGrayEye,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Unboxing',
            url: 'https://www.youtube.com/watch?v=lzXZa72AGnA',
            thumbnail: cartierGrayBoxing,
            category: 'Youtube',
            urlId: 'watch?v=lzXZa72AGnA',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/iwcwatches',
            urlId: 'iwcwatches',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/IWCWatches',
            urlId: 'IWCWatches',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/iwcwatches',
            urlId: 'iwcwatches',
        },
        {
            category: 'Homepage',
            url: 'https://www.iwc.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const cartier000002LinkList = {
    profile: {
        dongwonLogo: cartierGrayLogo,
        nickname: '000002',
    },
    theme: {
        backgroundImage: cartierGrayBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'Commercial',
            url: 'https://www.youtube.com/watch?v=SSnVP-X_CLc',
            thumbnail: cartierGrayBell,
            category: 'Youtube',
            urlId: 'watch?v=SSnVP-X_CLc',
        },
        {
            id: 2,
            name: 'Fake VS. Real',
            url: 'https://legitcheck.app/guides/watches/iwc/real-vs-fake-iwc-portuguese-pilot-portofino/',
            thumbnail: cartierGrayEye,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'Unboxing',
            url: 'https://www.youtube.com/watch?v=lzXZa72AGnA',
            thumbnail: cartierGrayBoxing,
            category: 'Youtube',
            urlId: 'watch?v=lzXZa72AGnA',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/iwcwatches',
            urlId: 'iwcwatches',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/IWCWatches',
            urlId: 'IWCWatches',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/iwcwatches',
            urlId: 'iwcwatches',
        },
        {
            category: 'Homepage',
            url: 'https://www.iwc.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const logen001LinkList = {
    profile: {
        dongwonLogo: logenLogo,
        nickname: 'logen 001',
    },
    theme: {
        backgroundImage: logenBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '고객 문의',
            url: 'https://www.ilogen.com/m/customer/cs',
            thumbnail: logenCall,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '예약 확인',
            url: 'https://www.ilogen.com/m/reservation/list',
            thumbnail: logenPeople,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '지점 안내',
            url: 'https://www.ilogen.com/m/network/centerInfo',
            thumbnail: logenOffice,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/iwcwatches',
        //   urlId: 'iwcwatches',
        // },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/logenlogistics',
            urlId: 'logenlogistics',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/iwcwatches',
        //   urlId: 'iwcwatches',
        // },
        {
            category: 'Homepage',
            url: 'https://www.ilogen.com/m',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const logen002LinkList = {
    profile: {
        dongwonLogo: logenLogo,
        nickname: 'logen 002',
    },
    theme: {
        backgroundImage: logenBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '고객 문의',
            url: 'https://www.ilogen.com/m/customer/cs',
            thumbnail: logenCall,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '예약 확인',
            url: 'https://www.ilogen.com/m/reservation/list',
            thumbnail: logenPeople,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '지점 안내',
            url: 'https://www.ilogen.com/m/network/centerInfo',
            thumbnail: logenOffice,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/iwcwatches',
        //   urlId: 'iwcwatches',
        // },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/logenlogistics',
            urlId: 'logenlogistics',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/iwcwatches',
        //   urlId: 'iwcwatches',
        // },
        {
            category: 'Homepage',
            url: 'https://www.ilogen.com/m',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const logen003LinkList = {
    profile: {
        dongwonLogo: logenLogo,
        nickname: 'logen 003',
    },
    theme: {
        backgroundImage: logenBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '고객 문의',
            url: 'https://www.ilogen.com/m/customer/cs',
            thumbnail: logenCall,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '예약 확인',
            url: 'https://www.ilogen.com/m/reservation/list',
            thumbnail: logenPeople,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '지점 안내',
            url: 'https://www.ilogen.com/m/network/centerInfo',
            thumbnail: logenOffice,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        // {
        //   category: 'Instagram',
        //   url: 'https://www.instagram.com/iwcwatches',
        //   urlId: 'iwcwatches',
        // },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/logenlogistics',
            urlId: 'logenlogistics',
        },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/iwcwatches',
        //   urlId: 'iwcwatches',
        // },
        {
            category: 'Homepage',
            url: 'https://www.ilogen.com/m',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const looxLooe110001LinkList = {
    profile: {
        dongwonLogo: looxLooeLogo,
        nickname: '110001',
    },
    theme: {
        backgroundImage: looxLooeBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '제품 상세',
            url: 'https://www.looxloo.com/product/detail_brand.html?product_no=16885&brand_name_en=BLUEDOG&brand_code=B000000I&shop_no=1&brand_type=A',
            thumbnail: looxLooeProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '2023 스프링 룩북',
            url: 'https://makevu.me/fc004898c9',
            thumbnail: looxLooeLook,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '룩스루 추천템',
            url: 'https://www.youtube.com/shorts/re4wDsmq5sE',
            thumbnail: looxLooeItem,
            category: 'Youtube',
            urlId: 'shorts/re4wDsmq5sE',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/looxloo_official',
            urlId: 'looxloo_official',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/logenlogistics',
        //   urlId: 'logenlogistics',
        // },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@LOOXLOOOFFICIAL',
            urlId: '@LOOXLOOOFFICIAL',
        },
        {
            category: 'Homepage',
            url: 'https://www.looxloo.com/index.html ',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const looxLooe110002LinkList = {
    profile: {
        dongwonLogo: looxLooeLogo,
        nickname: '110002',
    },
    theme: {
        backgroundImage: looxLooeBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '제품 상세',
            url: 'https://www.looxloo.com/product/detail_brand.html?product_no=16885&brand_name_en=BLUEDOG&brand_code=B000000I&shop_no=1&brand_type=A',
            thumbnail: looxLooeProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '2023 스프링 룩북',
            url: 'https://makevu.me/fc004898c9',
            thumbnail: looxLooeLook,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '룩스루 추천템',
            url: 'https://www.youtube.com/shorts/re4wDsmq5sE',
            thumbnail: looxLooeItem,
            category: 'Youtube',
            urlId: 'shorts/re4wDsmq5sE',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/looxloo_official',
            urlId: 'looxloo_official',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/logenlogistics',
        //   urlId: 'logenlogistics',
        // },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/@LOOXLOOOFFICIAL',
            urlId: '@LOOXLOOOFFICIAL',
        },
        {
            category: 'Homepage',
            url: 'https://www.looxloo.com/index.html ',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const baba120001LinkList = {
    profile: {
        dongwonLogo: babaLogo,
        nickname: '120001',
    },
    theme: {
        backgroundImage: babaBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '제품 상세',
            url: 'https://pc.babathe.com/goods/indexGoodsDetail?goodsId=G322983008&searchQuery=ALL&dqId=',
            thumbnail: babaProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '2023 BABA 신상품',
            url: 'https://babathe.com/goodsnew/indexNew',
            thumbnail: babaNew,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'IZZAT BABA "the COATS"',
            url: 'https://www.youtube.com/shorts/q5EbL8vPBrk',
            thumbnail: babaIzzat,
            category: 'Youtube',
            urlId: 'shorts/re4wDsmq5sE',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/babathe_official',
            urlId: 'babathe_official',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/logenlogistics',
        //   urlId: 'logenlogistics',
        // },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCe4xgEoigrAyDL_Xqe79jhQ',
            urlId: 'channel/UCe4xgEoigrAyDL_Xqe79jhQ',
        },
        {
            category: 'Homepage',
            url: 'https://babathe.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const baba120002LinkList = {
    profile: {
        dongwonLogo: babaLogo,
        nickname: '120002',
    },
    theme: {
        backgroundImage: babaBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '제품 상세',
            url: 'https://pc.babathe.com/goods/indexGoodsDetail?goodsId=G322983008&searchQuery=ALL&dqId=',
            thumbnail: babaProduct,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '2023 BABA 신상품',
            url: 'https://babathe.com/goodsnew/indexNew',
            thumbnail: babaNew,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: 'IZZAT BABA "the COATS"',
            url: 'https://www.youtube.com/shorts/q5EbL8vPBrk',
            thumbnail: babaIzzat,
            category: 'Youtube',
            urlId: 'shorts/re4wDsmq5sE',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/babathe_official',
            urlId: 'babathe_official',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/logenlogistics',
        //   urlId: 'logenlogistics',
        // },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UCe4xgEoigrAyDL_Xqe79jhQ',
            urlId: 'channel/UCe4xgEoigrAyDL_Xqe79jhQ',
        },
        {
            category: 'Homepage',
            url: 'https://babathe.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const theoriginalLinkList = {
    profile: {
        dongwonLogo: theoriginalLogo,
        nickname: 'theoriginal',
    },
    theme: {
        backgroundImage: theoriginalBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '공연 초대권 이벤트',
            url: 'https://www.instagram.com/p/CpgplUbSjES/',
            thumbnail: theoriginalHome,
            category: 'Instagram',
            urlId: 'p/CpgplUbSjES/',
        },
        {
            id: 2,
            name: 'VIENNA FESTA 2023',
            url: 'https://www.instagram.com/p/CpRub8uOyu2/',
            thumbnail: theoriginalNew,
            category: 'Instagram',
            urlId: 'p/CpRub8uOyu2/',
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/orchestra_the_original',
            urlId: 'orchestra_the_original',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/logenlogistics',
        //   urlId: 'logenlogistics',
        // },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/channel/UCe4xgEoigrAyDL_Xqe79jhQ',
        //   urlId: 'channel/UCe4xgEoigrAyDL_Xqe79jhQ',
        // },
        // {
        //   category: 'Homepage',
        //   url: 'http://theoriginal.kr',
        // },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};

export const whoauLinkList = {
    profile: {
        dongwonLogo: whoauLogo,
        nickname: 'whoau',
    },
    theme: {
        backgroundImage: whoauBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: 'New Collection',
            url: 'https://whoau.com/product/list.html?cate_no=23',
            thumbnail: whoauNew,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: '23CC TENNIS COLLECTION',
            url: 'https://whoau.com/product/list.html?cate_no=28',
            thumbnail: whoauBest,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 3,
            name: '스티브 반팔 카라티 체험단',
            url: 'https://whoau.com/board/event/read.html?no=6646&board_no=1002',
            thumbnail: whoauEvent,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/whoaukorea',
            urlId: 'whoaukorea',
        },
        {
            category: 'Facebook',
            url: 'https://www.facebook.com/whoaukorea',
            urlId: 'whoaukorea',
        },
        {
            category: 'Youtube',
            url: 'https://www.youtube.com/channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
            urlId: 'channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
        },
        {
            category: 'Homepage',
            url: 'https://whoau.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
export const tamnaalesLinkList = {
    profile: {
        dongwonLogo: tamnaalesLogo,
        nickname: 'tamnaales',
    },
    theme: {
        backgroundImage: tamnaalesBG,
        themeName: 'dongwon',
        font: 'Noto Sans KR',
    },
    list: [
        {
            id: 1,
            name: '우리술 양조장[서귀포]탐라에일',
            url: 'https://m.blog.naver.com/dnr6578/222264137318',
            thumbnail: tamnaalesBlog,
            category: 'Homepage',
            urlId: null,
        },
        {
            id: 2,
            name: 'Tistory 수제맥주 탐라에일',
            url: 'https://tamnaales.tistory.com/m/',
            thumbnail: tamnaalesTistory,
            category: 'Homepage',
            urlId: null,
        },
    ],
    snsList: [
        {
            category: 'Instagram',
            url: 'https://www.instagram.com/tamna_ales',
            urlId: 'tamna_ales',
        },
        // {
        //   category: 'Facebook',
        //   url: 'https://www.facebook.com/whoaukorea',
        //   urlId: 'whoaukorea',
        // },
        // {
        //   category: 'Youtube',
        //   url: 'https://www.youtube.com/channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
        //   urlId: 'channel/UC1o5U5AYGqV4DwP7fj4qrvQ',
        // },
        {
            category: 'Homepage',
            url: 'http://www.tamnaales.com',
        },
        // {
        //   category: 'Discord',
        //   url: 'https://discord.com/',
        // },
        // {
        //   category: 'Email',
        //   url: 'https://www.naver.com/',
        // },
        // {
        //   category: 'Tiktok',
        //   url: 'https://www.tiktok.com/ko-KR/',
        // },
        // {
        //   category: 'Twitter',
        //   url: 'https://twitter.com/',
        // },
        //
        // {
        //   category: 'Playstore',
        //   url: 'https://play.google.com/',
        // },
    ],
    feedList: [],
};
